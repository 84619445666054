

(() => {
  function getElementFullHeight(menuElement) {
    const computedStyles = window.getComputedStyle(menuElement);
    return menuElement.offsetHeight +
      parseFloat(computedStyles['marginTop']) +
      parseFloat(computedStyles['marginBottom']);
  }

  const menuItems = document.querySelectorAll("#topbar-menu a[href^='#'], #flyout-menu a");
  const menuHeight = getElementFullHeight(document.getElementById('mainNav'))
  document.documentElement.style.setProperty('--scroll-margin', `${menuHeight - 1}px`);

    menuItems.forEach(linkElement => {
      const targetElement = document.getElementById(linkElement.getAttribute('href').split('#')[1]);
      if (!targetElement) return;
      const observer = new IntersectionObserver((entries) => {
        setActiveMenuItem(linkElement, entries);
      }, { threshold: [0.19, 0.5] });

      observer.observe(targetElement);
    })

    const setActiveMenuItem = (linkElement, entries) => {
      entries.forEach(entry => {
        const parent = linkElement.parentNode;
        if (entry.isIntersecting)
          parent.classList.add('active');
        else
          parent.classList.remove('active');
      });
    }


  })


();